import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';

import { hamburger } from './modules/hamburger';
import { validate } from './modules/validate';

$(() => {
  hamburger();
  validate();
});

$('a[href^="#"]').click(function() {
  const headerHeight = $('.header').outerHeight();
  var speed = 500;
  var href = $(this).attr('href');
  var target = $(href == '#' || href == '' ? 'html' : href);

  if ($(this).hasClass('js-hamburgerLink')) {
    $('.js-hamburger').removeClass('is-active');
    $('.js-gnav').removeClass('is-active');
    $('body').removeClass('is-fixed');
  }

  var position = target.offset().top - headerHeight;
  setTimeout(function() {
    $('html, body').animate({ scrollTop: position }, speed, 'swing');
  }, 100);
  return false;
});

$(window).on('scroll', function() {
  const $scrollTop = $(window).scrollTop();

  if ($scrollTop > 100) {
    $('.header_cont').addClass('is-scroll');
  } else {
    $('.header_cont').removeClass('is-scroll');
  }
});
