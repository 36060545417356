export function hamburger() {
  const $hamburger = $('.js-hamburger');
  const $gnav = $('.js-gnav');

  $hamburger.on('click', () => {
    $hamburger.toggleClass('is-active');
    $gnav.toggleClass('is-active');
    $('body').toggleClass('is-fixed');
  });
}
